import { DateTime } from 'luxon';
import { NotificationDetailsBaseDto } from './notification-details-base.dto';

export interface SmsDto extends NotificationDetailsBaseDto {
    body: string;
}

export function mapSmsDto(dto: SmsDto): SmsDto {
    return {
        ...dto,
        timestamp: DateTime.fromISO(dto.timestamp as never as string),
    };
}
