import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter, Reference } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto, QueryRequestDto, QueryResultDto } from '@nuis/common';
import { firstValueFrom, map, Observable, of, switchMap } from 'rxjs';
import {
    EmailDto,
    mapEmailDto,
    mapNotificationDto,
    mapSmsDto,
    NotificationDetailsDto,
    NotificationDto,
    NotificationFilterDto,
    NotificationQueryResultDto,
    SmsDto,
} from '../../dtos';

@Injectable({
    providedIn: 'root',
})
export class NotificationApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);

    public getNotifications(
        options: QueryRequestDto<NotificationFilterDto>,
    ): Observable<QueryResultDto<NotificationDto>> {
        return this.http
            .post<
                NotificationQueryResultDto<NotificationDto & { timestamp: string; emailId: string | null }>
            >(`${this.config.baseUrl}Notifications/QueryList`, options)
            .pipe(
                map((response) => {
                    return {
                        records: response.notifications.map(mapNotificationDto),
                        totalRecords: response.totalRecords,
                    };
                }),
            );
    }

    public async getNotificationsByReference(reference: Reference) {
        const response = await firstValueFrom(
            this.http.get<(NotificationDto & { timestamp: string; emailId: string | null })[]>(
                `${this.config.baseUrl}Notifications/GetNotifications`,
                { params: { ...reference } },
            ),
        );

        return response.map(mapNotificationDto);
    }

    public getNotificationDetails(
        id: string,
    ): Observable<{ notification: NotificationDetailsDto; email: EmailDto | null; sms: SmsDto | null }> {
        return this.http
            .get<NotificationDetailsDto>(`${this.config.baseUrl}Notifications/GetNotification`, {
                params: { id: id },
            })
            .pipe(
                switchMap((notification) => {
                    if (notification.messageType === 'sms' && notification.smsId != null) {
                        return this.getSms(notification.smsId).pipe(map((sms) => ({ notification, email: null, sms })));
                    } else if (notification.messageType === 'email' && notification.emailId != null) {
                        return this.getEmail(notification.emailId).pipe(
                            map((email) => ({ notification, email, sms: null })),
                        );
                    } else {
                        return of({ notification, email: null, sms: null });
                    }
                }),
            );
    }

    private getEmail(id: string): Observable<EmailDto> {
        return this.http
            .get<EmailDto>(`${this.config.baseUrl}Notifications/GetEmail`, {
                params: { emailId: id },
            })
            .pipe(map(mapEmailDto));
    }

    private getSms(id: string): Observable<SmsDto> {
        return this.http
            .get<SmsDto>(`${this.config.baseUrl}Notifications/GetSms`, {
                params: {
                    smsId: id,
                },
            })
            .pipe(map(mapSmsDto));
    }

    public getMessagesTypes(): Observable<OverviewDto[]> {
        return of([
            { key: 'email', name: this.translate.instant('messageTypes.email'), color: null },
            { key: 'sms', name: this.translate.instant('messageTypes.sms'), color: null },
        ]);
    }

    public getNotificationTypes(): Observable<OverviewDto[]> {
        return of([
            { key: 'welcome', name: this.translate.instant('notificationTypes.welcome'), color: null },
            { key: 'start', name: this.translate.instant('notificationTypes.start'), color: null },
            { key: 'end', name: this.translate.instant('notificationTypes.end'), color: null },
            { key: 'startsuspended', name: this.translate.instant('notificationTypes.startsuspended'), color: null },
            { key: 'proposalLink', name: this.translate.instant('notificationTypes.proposalLink'), color: null },
            {
                key: 'deactivationSuccess',
                name: this.translate.instant('notificationTypes.deactivationSuccess'),
                color: null,
            },
            {
                key: 'startTravelWarning',
                name: this.translate.instant('notificationTypes.startTravelWarning'),
                color: null,
            },
            {
                key: 'cancellationbycustomer',
                name: this.translate.instant('notificationTypes.cancellationbycustomer'),
                color: null,
            },
            {
                key: 'cancellationbyinsurer',
                name: this.translate.instant('notificationTypes.cancellationbyinsurer'),
                color: null,
            },
            {
                key: 'futureterminationdate',
                name: this.translate.instant('notificationTypes.futureterminationdate'),
                color: null,
            },
            {
                key: 'cancellationDueToOldAge',
                name: this.translate.instant('notificationTypes.cancellationDueToOldAge'),
                color: null,
            },
            {
                key: 'additionalInsuredPersonPreRemoval',
                name: this.translate.instant('notificationTypes.additionalInsuredPersonPreRemoval'),
                color: null,
            },
            {
                key: 'additionalInsuredPersonRemoval',
                name: this.translate.instant('notificationTypes.additionalInsuredPersonRemoval'),
                color: null,
            },
            {
                key: 'coverChangeSuccess',
                name: this.translate.instant('notificationTypes.coverChangeSuccess'),
                color: null,
            },
            {
                key: 'newCountryTravelWarning',
                name: this.translate.instant('notificationTypes.newCountryTravelWarning'),
                color: null,
            },
            { key: 'updatemsisdn', name: this.translate.instant('notificationTypes.updatemsisdn'), color: null },
            {
                key: 'policyendorsementgeneral',
                name: this.translate.instant('notificationTypes.policyendorsementgeneral'),
                color: null,
            },
            {
                key: 'partialTravelWarning',
                name: this.translate.instant('notificationTypes.partialTravelWarning'),
                color: null,
            },
            {
                key: 'exceededtraveltermination',
                name: this.translate.instant('notificationTypes.exceededtraveltermination'),
                color: null,
            },
            {
                key: 'ExceededTravelTermination',
                name: this.translate.instant('notificationTypes.exceededtraveltermination'),
                color: null,
            },
            {
                key: 'contractSuspended',
                name: this.translate.instant('notificationTypes.contractSuspended'),
                color: null,
            },
            {
                key: 'deactivationFailedExpired',
                name: this.translate.instant('notificationTypes.deactivationFailedExpired'),
                color: null,
            },
            {
                key: 'contractSuspendedNoTravel',
                name: this.translate.instant('notificationTypes.contractSuspendedNoTravel'),
                color: null,
            },
            {
                key: 'proposalLegalInformation',
                name: this.translate.instant('notificationTypes.proposalLegalInformation'),
                color: null,
            },
            {
                key: 'PromotionExpiredNotification',
                name: this.translate.instant('notificationTypes.promotionExpiredNotification'),
                color: null,
            },
            {
                key: 'updateCover',
                name: this.translate.instant('notificationTypes.updateCover'),
                color: null,
            },
        ]);
    }

    public getNotificationStatuses(): Observable<OverviewDto[]> {
        return of([
            { key: 'Sent', name: this.translate.instant('notification.status.sent'), color: 'gray' },
            { key: 'SentByPartner', name: this.translate.instant('notification.status.sentByPartner'), color: 'gray' },
            { key: 'Open', name: this.translate.instant('notification.status.open'), color: 'orange' },
            { key: 'Failed', name: this.translate.instant('notification.status.failed'), color: 'red' },
        ]);
    }
}
