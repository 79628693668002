import { DateTime } from 'luxon';
import { NotificationDetailsBaseDto } from './notification-details-base.dto';

export interface EmailDto extends NotificationDetailsBaseDto {
    subject: string;
    body: string;
}

export function mapEmailDto(dto: EmailDto): EmailDto {
    return {
        ...dto,
        timestamp: DateTime.fromISO(dto.timestamp as never as string),
    };
}
